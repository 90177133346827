<template>
  <div id="nav">
    <router-link to="/">Home</router-link>
  </div>
  <router-view />
  <footer>
    <p>© 2021 KingsTableApp.com All rights reserved</p>
    <p>
      <a class="footer" href="mailto:gmtpoker21@gmail.com">Contact Us!</a>
    </p>
    <p data-cy="appVersion">App Version: {{ appVersion }}</p>
    <p data-cy="apiVersion">API Version: {{ apiVersion }}</p>
  </footer>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  background-color: black;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #50c878;
}
a.footer {
  color: #dde1e2;
}
/* #nav a.router-link-exact-active {
  color: #50c878;
} */
</style>

<script>
export default {
  mounted() {
    this.getAPIVersion();
    this.getAppVersion();
  },
  data() {
    return {
      appVersion: "0.0.0",
      apiVersion: "0.0.0",
    };
  },
  methods: {
    getAPIVersion() {
      fetch(`${process.env.VUE_APP_BASE_URL}/version`)
        .then((response) => response.json())
        .then((data) => {
          this.apiVersion = data.version;
        });
    },
    getAppVersion() {
      const pj = require("../package.json").version;
      this.appVersion = pj;
    },
  },
};
</script>
