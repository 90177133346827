<template>
  <h1>Edit Player</h1>
  <form @submit.prevent="editPlayer()">
    <div>
      <input
        data-cy="firstName"
        required
        v-model="firstName"
        placeholder="First Name"
      />
    </div>
    <div>
      <input
        data-cy="lastName"
        required
        v-model="lastName"
        placeholder="Last Name"
      />
    </div>
    <div>
      <textarea data-cy="bio" v-model="bio" placeholder="Player Bio"></textarea>
    </div>
    <div>
      <input data-cy="twitter" v-model="twitter" placeholder="Twitter Handle" />
    </div>
    <div>
      <select
        v-model="location"
        required
        data-cy="location"
        aria-label="location"
      >
        <option disabled value="">Location</option>
        <option v-for="option in locations" v-bind:value="option.name">
          {{ option.name }}
        </option>
      </select>
    </div>
    <div>
      <select
        v-model="playerType"
        required
        data-cy="playerType"
        aria-label="player type"
      >
        <option disabled value="">Type</option>
        <option value="Professional">Professional</option>
        <option value="Recreational">Recreational</option>
      </select>
    </div>
    <div>
      <select v-model="site" required data-cy="site" aria-label="site">
        <option disabled value="">Select Site</option>
        <option v-for="option in sites" v-bind:value="option.site">
          {{ option.site }}
        </option>
      </select>
    </div>
    <div>
      <input data-cy="handle" required v-model="handle" placeholder="Handle" />
    </div>
    <div class="submit">
      <button data-cy="savePlayerButton">Save Player</button>
    </div>
  </form>
</template>
<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  mounted() {
    this.fetchSites();
    this.fetchLocations();
    this.search();
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      locations: [],
      playerType: "",
      sites: [],
      bio: "",
      twitter: "",
      site: "",
      handle: "",
      location: "",
    };
  },
  methods: {
    search() {
      fetch(`${process.env.VUE_APP_BASE_URL}/players/${this.$route.params.id}`)
        .then((response) => response.json())
        .then((data) => {
          this.players = data;
          this.firstName = data.firstName;
          this.lastName = data.lastName;
          this.location = data.country;
          this.playerType = data.playerType;
          this.twitter = data.twitter;
          this.bio = data.bio;
          this.site = data.site;
          this.handle = data.handle;
        });
    },
    fetchSites() {
      fetch(`${process.env.VUE_APP_BASE_URL}/sites?active=true`)
        .then((response) => response.json())
        .then((data) => {
          this.sites = data;
        });
    },
    fetchLocations() {
      fetch(`${process.env.VUE_APP_BASE_URL}/location`)
        .then((response) => response.json())
        .then((data) => {
          this.locations = data;
        });
    },
    editPlayer() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstName: this.firstName,
          lastName: this.lastName,
          country: this.location,
          playerType: this.playerType,
          bio: this.bio,
          twitter: this.twitter,
          site: this.site,
          handle: this.handle.toLowerCase(),
        }),
      };
      fetch(
        `${process.env.VUE_APP_BASE_URL}/players/${this.$route.params.id}`,
        requestOptions
      ).then(async (response) => {
        const data = await response.json();
        if (response.status !== 200) {
          this.$toast.error("Player NOT Edited!", {
            position: "top",
            duration: 3000,
          });
        } else {
          this.$router.push({ name: "Home" });
          this.$toast.success("Player Edited!", {
            position: "top",
            duration: 3000,
          });
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
p.error {
  color: red;
  font-weight: bold;
}
input,
textarea {
  width: 50%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  /* border-bottom: 4px solid green; */
  background-color: #50c878;
  color: black;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
}
input:focus {
  border: 4px solid white;
}
textarea:focus {
  border: 4px solid white;
}
select:focus {
  border: 4px solid gold;
}
input::placeholder,
textarea::placeholder {
  color: black;
  font-weight: bold;
}
select {
  width: 50%;
  padding: 16px 20px;
  border: none;
  border-radius: 25px;
  background-color: black;
  color: white;
  margin: 8px 0;
}
button {
  background-color: #e0115f;
  border: none;
  color: white;
  padding: 16px 32px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  font-weight: bold;
}
button:focus {
  border: 4px solid white;
}
</style>
