<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>The fastest way to identify your table!</p>
    <p>
      Enter at least one search criteria (handle, first name, or last name) to
      search for a player
    </p>
  </div>
  <form @submit.prevent="search()">
    <div>
      <select
        v-model="selected"
        required
        data-cy="site"
        aria-label="select site"
      >
        <option disabled value="">Select Site</option>
        <option v-for="option in sites" v-bind:value="option.site">
          {{ option.site }}
        </option>
      </select>
    </div>
    <div>
      <input v-model="player" data-cy="search" placeholder="Player Handle" />
    </div>

    <div>
      <input v-model="firstName" data-cy="firstName" placeholder="First Name" />
    </div>

    <div>
      <input v-model="lastName" data-cy="lastName" placeholder="Last Name" />
    </div>

    <div class="submit">
      <button data-cy="searchButton">Search</button>
    </div>
    <div>
      <p data-cy="error" class="error" v-if="showError">Player Not Found!</p>
    </div>
    <div>
      <p data-cy="criteria-error" class="error" v-if="showError2">
        Please Enter At Least One Search Criteria!
      </p>
    </div>
  </form>
  <div>
    <button data-cy="clearButton" class="clear" v-on:click="clear()">
      Clear
    </button>
  </div>
  <div></div>
  <table style="width: 100%" data-cy="resultsTable">
    <thead></thead>
    <tbody>
      <tr>
        <th>Name</th>
        <th>Location</th>
        <th>Type</th>
        <th>Site</th>
        <th>Handle</th>
        <th>Edit</th>
      </tr>
      <tr v-for="option in players" v-bind:value="option.firstName">
        <td>{{ option.firstName }} {{ option.lastName }}</td>
        <td>{{ option.country }}</td>
        <td>{{ option.playerType }}</td>
        <td>{{ option.site }}</td>
        <td>{{ option.handle }}</td>
        <td>
          <button
            data-cy="editButton"
            class="edit"
            @click="editProfile(option._id)"
          >
            Edit
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div>
    <button data-cy="addButton" class="add" v-on:click="addPlayer()">
      Add Player
    </button>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  mounted() {
    this.fetchSites();
  },
  data() {
    return {
      players: [],
      selected: "",
      sites: [],
      player: "",
      showError: false,
      showError2: false,
      firstName: "",
      lastName: "",
    };
  },
  methods: {
    clear() {
      this.selected = "";
      this.player = "";
      this.showError = false;
      this.showError2 = false;
      this.firstName = "";
      this.lastName = "";
      this.players = [];
    },
    search() {
      if (this.firstName === "" && this.lastName === "" && this.player === "") {
        this.showError2 = !this.showError2;
        if (this.showError) {
          this.showError = !this.showError;
        }
      } else {
        if (this.showError2) {
          this.showError2 = !this.showError2;
        }
        const searchType = this.searchBuilder();
        console.log(searchType);
        fetch(searchType)
          .then((response) => response.json())
          .then((data) => {
            this.players = data;
            if (data.length == 0) {
              this.showError = !this.showError;
            } else {
              this.showError = false;
            }
          });
      }
    },

    searchBuilder() {
      let url = `${process.env.VUE_APP_BASE_URL}/players?site=${this.selected}`;

      if (this.player !== "") {
        url = url + `&handle=${this.player.toLowerCase()}`;
      }
      if (this.firstName !== "") {
        url = url + `&firstName=${this.capitalizeFirstLetter(this.firstName)}`;
      }
      if (this.lastName !== "") {
        url = url + `&lastName=${this.capitalizeFirstLetter(this.lastName)}`;
      }
      return url;
    },
    fetchSites() {
      fetch(`${process.env.VUE_APP_BASE_URL}/sites?active=true`)
        .then((response) => response.json())
        .then((data) => {
          this.sites = data;
        });
    },
    editProfile(playerId) {
      this.$router.push({ name: "Profile", params: { id: playerId } });
    },
    addPlayer() {
      localStorage.setItem("playerHandle", this.player.toLowerCase());
      localStorage.setItem("site", this.selected);
      localStorage.setItem(
        "firstName",
        this.capitalizeFirstLetter(this.firstName)
      );
      localStorage.setItem(
        "lastName",
        this.capitalizeFirstLetter(this.lastName)
      );
      this.$router.push({ name: "Add" });
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
p.error {
  color: red;
  font-weight: bold;
}
input {
  width: 50%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  /* border-bottom: 4px solid green; */
  background-color: #50c878;
  color: black;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
}
input:focus {
  border: 4px solid white;
}
input::placeholder {
  color: black;
  font-weight: bold;
}
select {
  width: 50%;
  padding: 16px 20px;
  border: none;
  border-radius: 25px;
  background-color: black;
  color: white;
  margin: 8px 0;
}
button {
  background-color: #e0115f;
  border: none;
  color: white;
  padding: 16px 32px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  font-weight: bold;
}
button.clear {
  background-color: black;
  border: none;
  color: white;
  padding: 16px 32px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  font-weight: bold;
}
button.edit {
  background-color: #85c3f4;
  border: none;
  color: black;
  padding: 16px 32px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  font-weight: bold;
}
button.add {
  background-color: gold;
  border: none;
  color: black;
  padding: 16px 32px;
  text-decoration: none;
  font-weight: bold;
  margin: 4px 2px;
  cursor: pointer;
}
button:focus {
  border: 4px solid white;
}
select:focus {
  border: 4px solid gold;
}
table {
  padding: 20px;
}
</style>
